import React from "react";
import { Link } from "gatsby";
import LoginLayout from "../components/layouts/loginLayout";
import Card from "react-bootstrap/Card";
import {Form, InputGroup} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AppAlert from "../components/appAlert";
import PasswordInput from "../components/passwordInput";
import {observable} from "mobx";
import alertService from "../services/alert";
import {Alert, AlertType} from "../models/alert";
import userService from "../services/user"
import * as messages from "../components/ui_messages";
import * as EmailValidator from 'email-validator';

const RegisterPage = () => {
    const emailRef = React.createRef<HTMLInputElement>();
    const codeRef = React.createRef<HTMLInputElement>();

    let password = observable({val:""});

    async function handleSubmit(event) {
        event.preventDefault();
        const email = emailRef.current?.value as string;
        const code = codeRef.current?.value as string;

        const validationMessage = ValidateForm();

        if (validationMessage.length > 0)
        {
            alertService.clear('register');
            alertService.push(new Alert(validationMessage, 'register', AlertType.Error))
            return;
        }

        console.info(`Registering ${email}, password: ${password.val}, code: ${code}`);

        userService.register(email, password.val, password.val, code, "http://seventeendaysweb.org/#/app/register", "default");
    }

    function ValidateForm()
    {
        if (!emailRef.current?.value)
        {
            return messages.REGISTER_EMAIL_REQUIRED;
        }

        let email = emailRef.current?.value as string;

        if (!EmailValidator.validate(email))
        {
            return messages.REGISTER_INVALID_EMAIL_FORMAT;
        }

        if (password.val == null || password.val.length < 8)
        {
            return messages.REGISTER_PASSWORD_TOO_SHORT;
        }

        return "";
    }

    return (
        <LoginLayout title="Register">
            <Card bg='light' className="p-2 mb-5">
                <Card.Body className="pt-3 pb-2">
                    <h1>Registration</h1>
                    <p>
                        Enter your email address, a password, and activation code to register your account.
                    <br/>
                        If you do not enter an activation code, you will only be able to see <Link to="/preview">the preview</Link>.
                    </p>
                    <Form>
                        <Form.Group controlId="formEmailAddress">
                            <Form.Control placeholder="Email Address" ref={emailRef}/>
                        </Form.Group>

                        <Form.Group controlId="formPassword">
                            <PasswordInput name="password" value={password} />
                        </Form.Group>

                        <Form.Group controlId="formRegCode">
                            <Form.Control placeholder="Activation Code" ref={codeRef}/>
                        </Form.Group>
                    </Form>
                    <p>
                        <Button variant="primary" onClick={handleSubmit}>Register</Button>
                    </p>
                    <AppAlert/>
                </Card.Body>
            </Card>
            <Link to="/">Back to the Welcome Page?</Link>
        </LoginLayout>
    )
}

export default RegisterPage
